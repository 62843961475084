import ApiService from "@/common/api.service";
export default {
  getActs() {
    return ApiService.query("acts");
  },
  getExamId(data) {
    return ApiService.query("acts/get-exam-id", data);
  },
  getActExamId(data) {
    return ApiService.query("acts/get-exam-id", data);
  },
  getExamInfo(exam_id) {
    return ApiService.query("acts/exam/" + exam_id);
  },
  getNewExam(exam_id, data) {
    return ApiService.post("acts/questions/" + exam_id, data);
  },
  getOldExam(user_exam_id) {
    return ApiService.query("acts/answer/" + user_exam_id);
  },
  gradeScore(user_exam_id, data) {
    return ApiService.update("acts/answer", user_exam_id, data);
  },
  getRes(user_exam_id) {
    return ApiService.get("acts/transcript", user_exam_id);
  },
  getAnswers(exam_id) {
    return ApiService.get(`acts/${exam_id}`, "answers");
  },
  createAnswers(data) {
    return ApiService.post("acts", data);
  },
  updateAnswers(exam_id, data) {
    return ApiService.update(`acts/${exam_id}`, "answers", data);
  },
  getStudents() {
    return ApiService.query("students");
  },
  getResults(exam_id, data) {
    return ApiService.query("acts/student/tests/" + exam_id, data);
  },
  getInfo(exam_id) {
    return ApiService.query(`acts/${exam_id}/info`);
  },
  updateInfo(exam_id, data) {
    return ApiService.update(`acts/${exam_id}`, "info", data);
  },
  getConversion(exam_id) {
    return ApiService.query(`acts/${exam_id}/conversion`);
  },
  updateConversion(exam_id, data) {
    return ApiService.update(`acts/${exam_id}`, "conversion", data);
  }
};
