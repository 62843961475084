<template>
  <div class="container grading">
    <el-dialog
      title="Keyboard"
      :visible.sync="changeKeyboard"
      width="50%"
      center
    >
      <div class="keyboard">
        <el-radio-group v-model="keyboard_select" class="row">
          <div class="col-md-6">
            <el-radio label="2">
              <img
                :class="keyboard_select === '2' ? 'keyboard_on' : ''"
                :src="keyboard_list[1]"
              />
            </el-radio>
          </div>
          <div class="col-md-6">
            <el-radio label="1">
              <img
                :class="keyboard_select === '1' ? 'keyboard_on' : ''"
                :src="keyboard_list[0]"
              />
            </el-radio>
          </div>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeKeyboard = false">取 消</el-button>
        <el-button type="primary" @click="saveKeyboard">
          確 定
        </el-button>
      </span>
    </el-dialog>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <a href="/tests">Tests</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <a href="/tests/grading">Grading</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>ACT</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>
          {{ sat_info.full_name }} ({{ sat_info.title }})
        </el-breadcrumb-item> -->
      </template>
    </Breadcrumb>
    <h3>GRADING - {{ sat_info.full_name }}({{ sat_info.title }})</h3>
    <el-divider></el-divider>
    <el-form label-width="120px">
      <el-form-item label="Keyboard:">
        <div class="row">
          <div class="col-md-4">
            <img :src="keyboard_url" style="width:100%" />
          </div>
          <div class="col-md-4">
            <el-button @click="changeKeyboard = true">
              <i class="fa fa-exchange-alt"></i>
              Change
            </el-button>
          </div>
        </div>
      </el-form-item>
      <div v-if="teacher_id > 0">
        <el-form-item label="Class:">
          <h4 style="margin-bottom:0;line-height:40px">
            {{ classInfo["title"] }}
          </h4>
        </el-form-item>
      </div>
      <div v-if="$route.query.user_exam_id === undefined">
        <el-form-item label="Students:" class="Students" v-if="canEdit">
          <div class="row">
            <div class="col-md-6">
              <el-select
                v-model="student"
                placeholder="Select student"
                filterable
                style="width:100%"
                @change="getResults"
              >
                <el-option
                  v-for="student in students"
                  :key="student.id"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' (' +
                      student.email +
                      ')'
                  "
                  :value="student.user_id"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <el-select
                v-model="result"
                placeholder="Select results"
                filterable
                style="width:100%"
                @change="selectResults"
              >
                <el-option label="New Test" value="0"></el-option>
                <el-option
                  v-for="result in results"
                  :key="result.user_exam_id"
                  :label="result.date_taken + '(' + result.total_score + ')'"
                  :value="result.user_exam_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Students:" class="Students" v-else>
          <div class="row">
            <div class="col-md-6">
              <el-select
                v-model="result"
                placeholder="Select results"
                filterable
                style="width:100%"
                @change="selectResults"
              >
                <el-option label="New Test" value="0"></el-option>
                <el-option
                  v-for="result in results"
                  :key="result.user_exam_id"
                  :label="result.date_taken + '(' + result.total_score + ')'"
                  :value="result.user_exam_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="Students:" class="Students" v-if="canEdit">
          <div class="row">
            <div class="col-md-6">
              <el-select
                v-model="student"
                placeholder="Select student"
                filterable
                style="width:100%"
                @change="getResults"
                no-data-text="Loading..."
              >
                <el-option
                  v-for="student in students"
                  :key="student.id"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' (' +
                      student.email +
                      ')'
                  "
                  :value="student.user_id"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-6">
              <el-select
                v-model="user_exam_id"
                placeholder="Select results"
                filterable
                style="width:100%"
                @change="selectResults"
              >
                <el-option label="New Test" value="0"></el-option>
                <el-option
                  v-for="result in results"
                  :key="result.user_exam_id"
                  :label="result.date_taken + '(' + result.total_score + ')'"
                  :value="result.user_exam_id"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Student:" class="Students" v-else>
          <div class="row">
            <div class="col-md-6">
              <b>{{ this.profile.name }}</b>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Exam taken:">
          <div class="row">
            <div class="col-md-6">
              <span class="pl-20">
                <el-date-picker
                  v-model="exam_date"
                  type="datetime"
                  placeholder="Exam taken"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </span>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div v-show="user_exam_id !== ''">
      <el-divider></el-divider>
      <!-- English -->
      <div>
        <h4>SECTION 1: English</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="grammar.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="grammar.answerString"
          @input="setGrammar(grammar.count)"
        >
        </el-input>
        <div class="row answer">
          <div class="col-md-2" v-for="col in grammarStyle.col" :key="col">
            <div
              class="question"
              v-for="(num, index) in grammarStyle.colCount"
              v-show="grammar.count > grammar.colCount * (col - 1) + index"
              :key="num"
            >
              <div
                class="row"
                v-if="
                  grammar.answerArr[grammar.colCount * (col - 1) + index] !=
                    undefined
                "
              >
                <div class="col-sm-1-5 num">
                  <span>{{ grammar.colCount * (col - 1) + num }}</span>
                </div>
                <div
                  class="col-sm-1-5 option"
                  v-for="option in grammar.options[
                    (grammar.colCount * (col - 1) + num) % 2
                  ]"
                  :key="option"
                >
                  <el-radio
                    v-model="
                      grammar.answerArr[grammar.colCount * (col - 1) + index]
                        .answer
                    "
                    :label="option"
                  >
                    <span
                      :class="
                        grammar.answerArr[grammar.colCount * (col - 1) + index]
                          .answer == option
                          ? 'answer_active'
                          : ''
                      "
                      >{{ option }}</span
                    >
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- Math -->
      <div>
        <h4>SECTION 2: Math</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="math.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="math.answerString"
          @input="setMath(math.count)"
        >
        </el-input>
        <div class="row answer">
          <div class="col-md-2" v-for="col in mathStyle.col" :key="col">
            <div
              class="question"
              v-for="(num, index) in mathStyle.colCount"
              v-show="math.count > math.colCount * (col - 1) + index"
              :key="num"
            >
              <div
                class="row"
                v-if="
                  math.answerArr[math.colCount * (col - 1) + index] != undefined
                "
              >
                <div class="col-2 num">
                  <span>{{ math.colCount * (col - 1) + num }}</span>
                </div>
                <div
                  class="col-2 option"
                  v-for="option in math.options[
                    (math.colCount * (col - 1) + num) % 2
                  ]"
                  :key="option"
                >
                  <el-radio
                    v-model="
                      math.answerArr[math.colCount * (col - 1) + index].answer
                    "
                    :label="option"
                  >
                    <span
                      :class="
                        math.answerArr[math.colCount * (col - 1) + index]
                          .answer == option
                          ? 'answer_active'
                          : ''
                      "
                      >{{ option }}</span
                    >
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- Reading-->
      <div>
        <h4>SECTION 3: Reading</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="reading.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="reading.answerString"
          @input="setReading(reading.count)"
        >
        </el-input>
        <div class="row answer">
          <div class="col-md-2" v-for="col in readingStyle.col" :key="col">
            <div
              class="question"
              v-for="(num, index) in readingStyle.colCount"
              v-show="reading.count > reading.colCount * (col - 1) + index"
              :key="num"
            >
              <div
                class="row"
                v-if="
                  reading.answerArr[reading.colCount * (col - 1) + index] !=
                    undefined
                "
              >
                <div class="col-sm-1-5 num">
                  <span>{{ reading.colCount * (col - 1) + num }}</span>
                </div>
                <div
                  class="col-sm-1-6 option"
                  v-for="option in reading.options[
                    (reading.colCount * (col - 1) + num) % 2
                  ]"
                  :key="option"
                >
                  <el-radio
                    v-model="
                      reading.answerArr[reading.colCount * (col - 1) + index]
                        .answer
                    "
                    :label="option"
                  >
                    <span
                      :class="
                        reading.answerArr[reading.colCount * (col - 1) + index]
                          .answer == option
                          ? 'answer_active'
                          : ''
                      "
                      >{{ option }}</span
                    >
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- Science-->
      <div>
        <h4>SECTION 4: Science</h4>
        <el-input
          type="textarea"
          :rows="2"
          :maxlength="mathc.count"
          show-word-limit
          placeholder="Please type or paste your answers here, and they will be auto-filled in the answer sheet below."
          v-model="mathc.answerString"
          @input="setMathc"
        >
        </el-input>
        <div class="row answer">
          <div class="col-md-2" v-for="col in mathcStyle.col" :key="col">
            <div
              class="question"
              v-for="(num, index) in mathcStyle.colCount"
              v-show="mathc.count > mathc.colCount * (col - 1) + index"
              :key="num"
            >
              <div
                class="row"
                v-if="
                  mathc.answerArr[mathc.colCount * (col - 1) + index] !=
                    undefined
                "
              >
                <div class="col-sm-2 num">
                  <span>{{ mathc.colCount * (col - 1) + num }}</span>
                </div>
                <div
                  class="col-sm-2 option"
                  v-for="option in mathc.options[
                    (mathc.colCount * (col - 1) + num) % 2
                  ]"
                  :key="option"
                >
                  <el-radio
                    v-model="
                      mathc.answerArr[mathc.colCount * (col - 1) + index].answer
                    "
                    :label="option"
                  >
                    <span
                      :class="
                        mathc.answerArr[mathc.colCount * (col - 1) + index]
                          .answer == option
                          ? 'answer_active'
                          : ''
                      "
                      >{{ option }}</span
                    >
                  </el-radio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div v-show="false">
        <!-- Essay -->
        <div>
          <el-radio-group v-model="hasEssay" class="hasEssay" size="small">
            <el-radio-button label="0">No Essay</el-radio-button>
            <el-radio-button label="1">Has Essay</el-radio-button>
          </el-radio-group>
          <div v-if="hasEssay == '1'">
            <h4>Section 5: Essay</h4>

            <div class="row escore">
              <div class="col-md-12">
                Score:
                <el-select v-model="essayScore" placeholder="Score">
                  <el-option
                    v-for="item in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <el-radio-group
              v-model="uploadImg"
              class="hasEssay"
              size="mini"
              style="margin-bottom:10px"
            >
              <el-radio-button label="1">上傳Essay圖片</el-radio-button>
              <el-radio-button label="0">手動輸入Essay</el-radio-button>
            </el-radio-group>
            <div v-show="uploadImg === '1'">
              <div>
                <div>
                  <el-upload
                    class="upload-demo"
                    drag
                    :action="postUrl"
                    :headers="token"
                    :data="{ path: 'results' }"
                    name="image"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <div>
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        将Essay文件/图片拖到此处，或<em>点击上传</em>
                      </div>
                    </div>
                  </el-upload>
                </div>
                <div style="display:flex">
                  <draggable :list="essay[0].answer.img_url" class="view-image">
                    <div
                      class="view-image-item"
                      v-for="(image, key) in essay[0].answer.img_url"
                      :key="key"
                    >
                      <el-image
                        style="width: 150px; height: 150px"
                        v-show="essay[0].answer.img_url.length > 0"
                        :src="essay[0].answer.img_url[key]"
                        :preview-src-list="essay[0].answer.img_url"
                      >
                      </el-image>
                      <div class="remove-icon">
                        <el-button
                          @click="removeImg(key)"
                          type="danger"
                          icon="el-icon-delete"
                          size="small"
                          circle
                        ></el-button>
                      </div>
                      <div class="number-icon">
                        <b>{{ key + 1 }}</b>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
            <div v-show="uploadImg === '0'">
              <el-input
                v-model="essay[0].answer.essay"
                type="textarea"
                :rows="10"
                placeholder="Please type or paste your essay here."
              >
              </el-input>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
      <div class="text-center">
        <el-button type="success" @click="postAnswer">Next</el-button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import _ from "lodash";
import TOEFL from "@/apis/act.js";
import draggable from "vuedraggable";
import JwtService from "@/common/jwt.service";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: "Grading - ACT"
    };
  },

  components: {
    Breadcrumb,
    draggable
  },

  mixins: [],

  props: [],
  data() {
    return {
      exam_date: null,
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      changeKeyboard: false,
      keyboard: "1",
      keyboard_select: "1",
      classIndex: null,
      classInfo: {},
      teacher_id: 0,
      hasEssay: "0",
      students: [],
      student: "",
      grammar: {
        col: 6,
        colCount: 13,
        count: 6 * 13 - 3,
        answerArr: [],
        answerString: "",
        options: [
          ["F", "G", "H", "J"],
          ["A", "B", "C", "D"]
        ]
      },
      math: {
        col: 6,
        colCount: 10,
        count: 6 * 10,
        answerArr: [],
        answerString: "",
        options: [
          ["F", "G", "H", "J", "K"],
          ["A", "B", "C", "D", "E"]
        ]
      },
      reading: {
        col: 6,
        colCount: 7,
        count: 6 * 7 - 2,
        answerArr: [],
        answerString: "",
        options: [
          ["F", "G", "H", "J"],
          ["A", "B", "C", "D"]
        ]
      },
      mathc: {
        col: 6,
        colCount: 7,
        count: 6 * 7 - 2,
        answerArr: [],
        answerString: "",
        options: [
          ["F", "G", "H", "J"],
          ["A", "B", "C", "D"]
        ]
      },
      essay: [
        {
          answer: {
            img_url: [],
            essay: ""
          }
        }
      ],
      sat_info: {},
      questions: [],
      result: "",
      results: [],
      user_exam_id: "",
      essayScore: 0,
      uploadImg: "1"
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    canEdit() {
      let canEdit = false;
      if (this.profile.all_roles != undefined) {
        if (
          this.profile.all_roles.indexOf("super-admin") > -1 ||
          this.profile.all_roles.indexOf("staff") > -1 ||
          this.profile.all_roles.indexOf("teacher") > -1
        ) {
          canEdit = true;
        } else {
          canEdit = false;
        }
      }
      return canEdit;
    },
    keyboard_url() {
      let url = "";
      if (this.keyboard == 1) {
        url =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type1.jpg";
      } else {
        url =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type2.jpg";
      }
      return url;
    },
    keyboard_list() {
      let url = [];
      url[0] =
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type1.jpg";
      url[1] =
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type2.jpg";
      return url;
    },
    readingStyle() {
      return this.getStyle(this.reading.col, this.reading.colCount);
    },
    grammarStyle() {
      return this.getStyle(this.grammar.col, this.grammar.colCount);
    },
    mathStyle() {
      return this.getStyle(this.math.col, this.math.colCount);
    },
    mathNumStyle() {
      let style = {
        col: [],
        colCount: []
      };
      for (let i = 1; i <= 4; i++) {
        style.col.push(i + this.math.count);
      }
      for (let i = 1; i <= 4; i++) {
        style.colCount.push(i);
      }
      return style;
    },
    mathcStyle() {
      return this.getStyle(this.mathc.col, this.mathc.colCount);
    },
    mathcNumStyle() {
      let style = {
        col: [],
        colCount: []
      };
      for (let i = 1; i <= 8; i++) {
        style.col.push(i + this.mathc.count);
      }
      for (let i = 1; i <= 4; i++) {
        style.colCount.push(i);
      }
      return style;
    },
    exam_id() {
      return this.$route.query.exam_id;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "image";
    },
    moment() {
      return moment();
    }
  },
  watch: {
    "reading.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.reading.count);
        this.reading.answerString = arr.join("");
      },
      deep: true
    },
    "grammar.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.grammar.count);
        this.grammar.answerString = arr.join("");
      },
      deep: true
    },
    "math.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.math.count);
        this.math.answerString = arr.join("");
      },
      deep: true
    },
    "mathc.answerArr": {
      handler(val) {
        let arr = this.textChange(val, this.keyboard, this.mathc.count);
        this.mathc.answerString = arr.join("");
      },
      deep: true
    },
    profile() {
      if (
        this.profile.all_roles.indexOf("super-admin") > -1 ||
        this.profile.all_roles.indexOf("staff") > -1 ||
        this.profile.all_roles.indexOf("teacher") > -1
      ) {
        this.getStudents();
      } else {
        this.getResults(this.profile.id);
      }
    }
  },

  async mounted() {
    if (this.$route.query.user_exam_id) {
      this.user_exam_id = parseInt(this.$route.query.user_exam_id);
    }
    if (this.$route.query.teacher_id) {
      this.teacher_id = parseInt(this.$route.query.teacher_id);
    }
    if (this.$route.query.classIndex !== null) {
      this.classIndex = this.$route.query.classIndex;
    }
    if (this.teacher_id > 0) {
      this.getGroupCourses(this.teacher_id);
    }
    if (this.$route.query.user_id) {
      this.student = parseInt(this.$route.query.user_id);
      const results = await TOEFL.getResults(this.exam_id, {
        user_id: this.student
      });
      this.results = results.user_exams;
      this.exam_date = moment().format("YYYY-MM-DD 09:00:00");
    }
    if (
      this.$route.query.user_id &&
      this.$route.query.user_exam_id === undefined
    ) {
      this.student = parseInt(this.$route.query.user_id);
      this.getResults(this.student);
    }
    this.getProfile();
    this.getExamInfo();
    if (this.user_exam_id > 0) {
      this.editResult(this.user_exam_id);
    }
    this.keyboard = this.$route.query.keyboard;
    this.keyboard_select = this.$route.query.keyboard;
  },
  methods: {
    async getExamInfo() {
      const info = await TOEFL.getExamInfo(this.exam_id);
      this.sat_info = info.exam;
    },
    async getStudents() {
      if (this.students.length <= 0) {
        const students = await TOEFL.getStudents();
        this.students = students;
      }
    },
    async getResults(e) {
      const results = await TOEFL.getResults(this.exam_id, {
        user_id: e
      });
      this.results = results.user_exams;
      if (this.results.length > 0) {
        this.selectResults(this.results[0].user_exam_id);
        this.result = this.results[0].user_exam_id;
        this.$router.push({
          name: "GradingACT",
          query: {
            keyboard: this.keyboard,
            exam_id: this.exam_id,
            user_id: this.student,
            user_exam_id: this.results[0].user_exam_id
          }
        });
      } else {
        this.startGrading();
        this.result = "0";
      }
    },
    async editResult(user_exam_id) {
      this.user_exam_id = user_exam_id;
      let res = await TOEFL.getOldExam(user_exam_id);
      this.essayScore = res.essayScore;
      //English
      let writing_arr = res.questions["english"];
      this.grammar.colCount = 13;
      this.grammar.col = Math.ceil(writing_arr.length / this.grammar.colCount);
      this.grammar.count = writing_arr.length;
      this.grammar.answerArr = writing_arr;

      //Math
      let math_arr = res.questions["math"];
      this.math.colCount = 10;
      this.math.col = Math.ceil(math_arr.length / this.math.colCount);
      this.math.count = math_arr.length;
      this.math.answerArr = math_arr;

      //Reading
      let reading_arr = res.questions["reading"];
      this.reading.colCount = 7;
      this.reading.col = Math.ceil(reading_arr.length / this.reading.colCount);
      this.reading.count = reading_arr.length;
      this.reading.answerArr = reading_arr;

      //Science
      let mathc_arr = res.questions["science"];
      this.mathc.colCount = 7;
      this.mathc.col = Math.ceil(mathc_arr.length / this.mathc.colCount);
      this.mathc.count = mathc_arr.length;
      this.mathc.answerArr = mathc_arr;

      //essay
      let essay_arr = res.questions["essay"];
      if (!_.isArray(essay_arr[0].answer.img_url)) {
        if (essay_arr[0].answer.img_url == "") {
          essay_arr[0].answer.img_url = [];
        } else {
          essay_arr[0].answer.img_url = [essay_arr[0].answer.img_url];
        }
      }

      if (
        essay_arr[0].answer.img_url.length > 0 ||
        essay_arr[0].answer.essay !== ""
      ) {
        this.hasEssay = 1;
      }
      this.essay = essay_arr;
    },
    async startGrading() {
      let res = {};
      if (this.canEdit) {
        res = await TOEFL.getNewExam(this.exam_id, {
          is_create: 0,
          user_id: this.student
        });
      } else {
        res = await TOEFL.getNewExam(this.exam_id, {
          is_create: 0
        });
      }
      this.user_exam_id = res.user_exam_id;
      this.$router.push({
        name: "GradingACT",
        query: {
          keyboard: this.keyboard,
          exam_id: this.exam_id,
          user_id: this.student,
          user_exam_id: res.user_exam_id
        }
      });
    },
    selectResults(e) {
      if (e > 0) {
        this.editResult(e);
      } else {
        this.startGrading();
      }
    },
    saveKeyboard() {
      if (this.keyboard == this.keyboard_select) {
        this.keyboard = this.keyboard_select;
      } else {
        this.keyboard = this.keyboard_select;
        this.reading.answerString = this.textChange(
          this.reading.answerArr,
          this.keyboard,
          this.reading.count
        ).join("");
        this.grammar.answerString = this.textChange(
          this.grammar.answerArr,
          this.keyboard,
          this.grammar.count
        ).join("");
        this.math.answerString = this.textChange(
          this.math.answerArr,
          this.keyboard,
          this.math.count
        ).join("");
        this.mathc.answerString = this.textChange(
          this.mathc.answerArr,
          this.keyboard,
          this.mathc.count
        ).join("");
      }
      this.changeKeyboard = false;
    },
    getStyle(col, colCount) {
      let style = {
        col: [],
        colCount: []
      };
      for (let i = 1; i <= col; i++) {
        style.col.push(i);
      }
      for (let i = 1; i <= colCount; i++) {
        style.colCount.push(i);
      }
      return style;
    },
    setReading(count) {
      //输入影响布局
      this.reading.answerString = this.reading.answerString.toLocaleUpperCase();
      let string = "";
      string = this.reading.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.reading.answerArr);
      if (changeArr.length >= count) {
        let next_section = document.getElementsByClassName(
          "el-textarea__inner"
        )[3];
        next_section.focus();
      }
    },
    setGrammar(count) {
      //输入影响布局
      this.grammar.answerString = this.grammar.answerString.toLocaleUpperCase();
      let string = "";
      string = this.grammar.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.grammar.answerArr);
      if (changeArr.length >= count) {
        let next_section = document.getElementsByClassName(
          "el-textarea__inner"
        )[1];
        next_section.focus();
      }
    },
    setMath(count) {
      //输入影响布局
      this.math.answerString = this.math.answerString.toLocaleUpperCase();
      let string = "";
      string = this.math.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.math.answerArr);
      if (changeArr.length >= count) {
        let next_section = document.getElementsByClassName(
          "el-textarea__inner"
        )[2];
        next_section.focus();
      }
    },
    setMathc() {
      //输入影响布局
      this.mathc.answerString = this.mathc.answerString.toLocaleUpperCase();
      let string = "";
      string = this.mathc.answerString;
      let changeArr = string.split("");
      this.keyboardChange(changeArr, this.keyboard, this.mathc.answerArr);
    },
    textChange(arr, type, count) {
      let newArr = [];
      if (type == "2") {
        _(arr).forEach(function(answer, index) {
          answer = answer.answer;
          if (index < count) {
            if (index % 2 == 0) {
              if (answer === undefined) {
                newArr.push("X");
              } else {
                if (answer === "A") {
                  newArr.push("A");
                } else if (answer === "B") {
                  newArr.push("S");
                } else if (answer === "C") {
                  newArr.push("D");
                } else if (answer === "D") {
                  newArr.push("F");
                } else if (answer === "E") {
                  newArr.push("G");
                } else if (answer === "") {
                  newArr.push("");
                } else {
                  newArr.push("X");
                }
              }
            } else {
              if (answer === undefined) {
                newArr.push("X");
              } else {
                if (answer === "F") {
                  newArr.push("A");
                } else if (answer === "G") {
                  newArr.push("S");
                } else if (answer === "H") {
                  newArr.push("D");
                } else if (answer === "J") {
                  newArr.push("F");
                } else if (answer === "K") {
                  newArr.push("G");
                } else if (answer === "") {
                  newArr.push("");
                } else {
                  newArr.push("X");
                }
              }
            }
          }
        });
      } else {
        _(arr).forEach(function(answer, index) {
          answer = answer.answer;
          if (index < count) {
            if (answer === undefined) {
              newArr.push("X");
            } else if (answer === "") {
              newArr.push("");
            } else {
              newArr.push(answer);
            }
          }
        });
      }
      return newArr;
    },
    keyboardChange(arr, type, ansArr) {
      let newArr = [];
      if (type == 2) {
        _(ansArr).forEach(function(item, index) {
          let answer = arr[index];
          if (answer == undefined) {
            answer = "";
          }
          if (index % 2 == 0) {
            if (answer === "A") {
              ansArr[index].answer = "A";
            } else if (answer === "S") {
              ansArr[index].answer = "B";
              newArr.push("B");
            } else if (answer === "D") {
              ansArr[index].answer = "C";
              newArr.push("C");
            } else if (answer === "F") {
              ansArr[index].answer = "D";
              newArr.push("D");
            } else if (answer === "G") {
              ansArr[index].answer = "D";
              newArr.push("E");
            } else if (answer === "") {
              ansArr[index].answer = "";
            } else {
              ansArr[index].answer = "X";
              newArr.push("X");
            }
          } else {
            if (answer === "A") {
              ansArr[index].answer = "F";
            } else if (answer === "S") {
              ansArr[index].answer = "G";
            } else if (answer === "D") {
              ansArr[index].answer = "H";
            } else if (answer === "F") {
              ansArr[index].answer = "J";
            } else if (answer === "G") {
              ansArr[index].answer = "K";
            } else if (answer === "") {
              ansArr[index].answer = "";
            } else {
              ansArr[index].answer = "X";
            }
          }
        });
      } else {
        _(ansArr).forEach(function(item, index) {
          let answer = arr[index];
          if (answer == undefined) {
            answer = "";
          }
          if (index % 2 == 0) {
            if (
              answer === "A" ||
              answer === "B" ||
              answer === "C" ||
              answer === "D" ||
              answer === "E" ||
              answer === ""
            ) {
              ansArr[index].answer = answer;
            } else {
              ansArr[index].answer = "X";
              newArr.push("X");
            }
          } else {
            if (
              answer === "F" ||
              answer === "G" ||
              answer === "H" ||
              answer === "J" ||
              answer === "K" ||
              answer === ""
            ) {
              ansArr[index].answer = answer;
            } else {
              ansArr[index].answer = "X";
            }
          }
        });
      }
      return newArr;
    },
    removeImg(key) {
      this.essay[0].answer.img_url.splice(key, 1);
    },
    handleAvatarSuccess(res) {
      this.essay[0].answer.img_url.push(res.image.url);
    },
    beforeAvatarUpload() {
      let canUpload = true;
      if (this.essay[0].answer.img_url.length == 4) {
        canUpload = false;
        this.$message("圖片不能超過4張！");
      }
      return canUpload;
    },
    postAnswer() {
      let english = this.grammar.answerArr;
      let math = this.math.answerArr;
      let reading = this.reading.answerArr;
      let science = this.mathc.answerArr;
      let essay = this.essay;
      let newArr = [];
      newArr = english
        .concat(math)
        .concat(reading)
        .concat(science)
        .concat(essay);
      this.gradeScore(newArr);
    },
    async gradeScore(answers) {
      await TOEFL.gradeScore(this.user_exam_id, {
        exam_question_id: 0,
        progress_remaining: 0,
        answers: answers,
        essayScore: this.essayScore,
        exam_date: this.exam_date
      });
      // this.routerPush({
      //   name: "TestResult",
      //   query: {
      //     user_exam_id: this.user_exam_id
      //   },
      //   params: {
      //     testType: "act"
      //   }
      // });
      window.location.href = "/act/result?user_exam_id=" + this.user_exam_id;
    },
    async getGroupCourses(teacher_uid) {
      const res = await TOEFL.getGroupCourses({
        teacher_id: teacher_uid
      });
      this.classInfo = res.session_classes[this.classIndex];
      this.students = res.session_classes[this.classIndex].students;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped>
.escore {
  margin-top: 15px;
  margin-bottom: 15px;
}
.grading {
  padding-bottom: 20px;
}
.question >>> .el-radio__label,
.math_option >>> .el-radio__label {
  padding: 0;
  display: block;
}
.question >>> .el-radio__input,
.math_option >>> .el-radio__input {
  display: none;
}

.question >>> .el-radio,
.math_option >>> .el-radio {
  width: 100%;
  margin-bottom: 0;
}

.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 20%;
  float: left;
}
.col-xs-1-6,
.col-sm-1-6,
.col-md-1-6,
.col-lg-1-6 {
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 16.6666666666667%;
  float: left;
}
.question {
  height: 25px;
  margin: 0 0 10px;
}
.answer {
  margin-top: 20px;
}

.numberAnswer {
  margin-top: 20px;
}

.question .option,
.answer_input,
.num,
.math_input {
  font-size: 14px;
  height: 25px;
  line-height: 23px;
  padding: 0;
  text-align: center;
}
.math_input input {
  font-size: 18px;
}
.question .option:hover,
.math_option:hover {
  cursor: pointer;
}

.question .option:hover span,
.math_option:hover span {
  background-color: #17a2b8;
  color: white;
  border-color: #17a2b8;
}
.question .option span,
.math_option span {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 21px;
  border: solid 2px #42a16a;
  border-radius: 5px;
  font-weight: 500;
}

.math_num {
  text-align: center;
  height: 31px;
}
.math_num span {
  display: inline-block;
  width: 30px;
  height: 31px;
  font-weight: 500;
}

.math_option,
.math_num {
  margin: 5px 0;
}
.answer_input input,
.math_input input {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.answer_active {
  background-color: #42a16a;
  color: white;
}
.num span {
  font-weight: 700;
}
.math_num span {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}

.math {
  margin-top: 30px;
}
.kbd_type img {
  height: 50px;
}
.kbd_type .type1 img:hover,
.kbd_type .type2 img:hover {
  opacity: 1;
}
.kbd_selected {
  opacity: 1;
}
.kbd_no {
  opacity: 0.2;
}
.submit {
  margin-bottom: 20px;
}
.submit button {
  float: right;
}
.btn:hover {
  background: #337ab7;
  border-color: #337ab7;
  opacity: 0.8;
}
.error {
  border-color: red;
}

.essay_toggle:hover {
  cursor: pointer;
}
.essay_score {
  width: 60px;
  height: 30px;
  margin-bottom: 0px;
}

.essay-score {
  margin: 20px 0;
}
.keyboard img {
  width: 100%;
  opacity: 0.2;
}
.keyboard >>> .el-radio__label {
  padding: 0;
}
.keyboard >>> .el-radio__input {
  display: none;
}
img.keyboard_on {
  opacity: 1;
}
.view-image {
  margin: 10px 0;
  display: flex;
}
.view-image-item {
  position: relative;
  margin-right: 15px;
}
.view-image-item >>> img {
  border-radius: 5px;
  border: 1px solid #ccc;
}
.view-image-item:hover .remove-icon {
  display: block;
}
.remove-icon {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.number-icon {
  position: absolute;
  color: #fff;
  background-color: #42a16a;
  text-align: center;
  line-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  bottom: 16px;
  left: 10px;
}

@media screen and (max-width: 736px) {
  .question {
    height: 45px;
  }
  .question .option,
  .question .num {
    font-size: 16px;
    height: 45px;
    line-height: 43px;
  }
  .question .option span {
    width: 45px;
    height: 45px;
    line-height: 41px;
    font-size: 16px;
  }
}
</style>
